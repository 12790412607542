import { Routes, Route, useLocation } from 'react-router-dom';
import { NotFound } from '../components/LegacyV4Components';

import { useAuth } from '../hooks/auth';
import Default from '../styles/layouts/default';
import NotFoundImage from '../assets/not_found.svg';

import { Customers } from '../pages/Customers';
import { Teams } from '../pages/Teams';
import { Dashboards } from '../pages/Dashboards';
import { Unit } from '../pages/Unit';
import { Customer } from '../pages/Customer';
import { MessageHistory } from '../pages/MessageHistory';
import { MessageHistoryList } from '../pages/MessageHistoryList';
import { CustomerStakeholdersView } from '../pages/CustomerStakeholdersView';
import { CustomerToolsView } from '../pages/CustomerToolsView';
import { Units } from '../pages/Units';
import { Profile } from '../pages/Profile';
import { ProfileEdit } from '../pages/ProfileEdit';
import { Headquarter } from '../pages/Heaquarter';
import { UnitVision } from '../pages/UnitVision';
import { MediaHub } from '../pages/MediaHub';
import { StackDigital } from '../pages/StackDigital';
import { PaymentOptions } from '../pages/PaymentOptions';
import { SubscriptionOptions } from '../pages/SubscriptionOptions';
import { SubscriptionSuccess } from '../pages/SubscriptionSuccess';
import { RoiWeek } from '../pages/RoiWeek';
import { PaymentSuccess } from '../pages/PaymentSuccess';
import { PipefyRequest } from '../pages/PipefyRequest';
import { WalletPaymentOptions } from '../pages/WalletPaymentOptions';
import { GatewayHQSeletor } from '../pages/GatewayHQSeletor';
import { GatewayHQCheckout } from '../pages/GatewayHQCheckout';
import { IntegrationsManual } from '../pages/IntegrationsManual';
import { EditCustomerVision } from '../components/AtomicDesign/organisms/EditCustomerVision';

import { CustomerHistoric } from '../pages/CustomerHistoric';
import { MetabaseResults } from '../pages/MetabaseResults';
import { KommoIntegration } from '../pages/KommoIntegration';
import { OperationalCapacity } from '../pages/OperationalCapacity';
import { EditOperationalCapacity } from '../pages/EditOperationalCapacity';
import { CustomerTeam } from '../pages/CustomerTeam';
import { ProductsCalculator } from '../pages/ProductsCalculator';
import { CustomerTeamEdit } from '../pages/CustomerTeamEdit';
import { KnowledgeBase } from '../pages/KnowledgeBase';
import { ProjectUserAssignedEdit } from '../pages/UserAssignedEdit';
import { NewPriceCalculatorEdit } from '../pages/NewPriceCalculatorEdit';
import { TiktokIntegration } from '../pages/TikTokIntegration';
import { OportunityManager } from '../pages/OportunityManager';
import { PlanOptions } from '../pages/PlanOptions';
import { CustomerAccounts } from '../pages/CustomerAccounts';

import { AudienceStore } from '../pages/AudienceStore';

import { CreateUnitPage } from '../pages/CreateUnit';
import { PaymentLookingAhead } from '../pages/PaymentLookingAhead';
import { BackofficeProjects } from '../pages/BackofficeProjects';
import ExternalRedirect from '../utils/externalRedirect';
import { RegisterUnitAccount } from '../pages/RegisterUnitAccount';
import { SAPInvoices } from '../pages/SAPInvoices';

export default function LoggedInRoutes() {
  const location = useLocation();
  const { user } = useAuth();

  const headquarterId = process.env.REACT_APP_HEADQUARTER_ID;
  const state = location.state as { backgroundLocation?: Location };
  const mediaHubUrl = process.env.REACT_APP_MEDIAHUB_URL;

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route path="/" element={<Default />}>
          <Route path="/backoffice/projects" element={<BackofficeProjects />} />
          <Route path="/clientes" element={<Customers />} />
          <Route path="/baseconhecimento/:path?" element={<KnowledgeBase />} />
          <Route index element={<Dashboards />} />
          <Route path="/dashboards" element={<Dashboards />} />
          <Route path="/dev/teams" element={<Teams />} />
          <Route path="/unidade" element={<Unit />} />
          <Route path="/unit/view/:typeUser/:unitId" element={<UnitVision />} />
          <Route path="/perfil/:id" element={<Profile />} />
          <Route path="/perfil/edit/:id" element={<ProfileEdit />} />
          <Route path="/customer/:id" element={<Customer />} />
          <Route path="/cliente" element={<Customer />} />
          <Route path="/cliente/:id" element={<Customer />} />
          <Route
            path="/newPriceCalculatorEdit"
            element={<NewPriceCalculatorEdit />}
          />
          <Route
            path="/newPriceCalculatorEdit/:id"
            element={<NewPriceCalculatorEdit />}
          />
          <Route
            path="/cliente/:id/stakeholders"
            element={<CustomerStakeholdersView />}
          />
          <Route
            path="/cliente/:id/ferramentas"
            element={<CustomerToolsView />}
          />
          <Route path="/clientes/config" element={<MediaHub />} />
          <Route
            path="/clientes/mediahub/conexao-de-contas/kommo"
            element={<KommoIntegration />}
          />
          <Route
            path="/clientes/mediahub/conexao-de-contas/tiktok"
            element={<TiktokIntegration />}
          />
          <Route path="/cliente/visao/:id" element={<EditCustomerVision />} />
          <Route path="/clientes/mediahub" element={<MediaHub />} />
          <Route path="/clientes/stackdigital" element={<StackDigital />} />
          <Route
            path="/clientes/mediahub/duvidas-frequentes"
            element={<IntegrationsManual />}
          />

          <Route path="/cliente/:id/team" element={<CustomerTeam />} />
          <Route path="/cliente/:id/team/edit" element={<CustomerTeamEdit />} />
          <Route
            path="/clientes/config/duvidas-frequentes"
            element={<IntegrationsManual />}
          />
          <Route
            path="/clientes/mediahub/resultados/:projectId"
            element={<MetabaseResults />}
          />
          <Route
            path="/clientes/mediahub/accounts/:projectId"
            element={<CustomerAccounts />}
          />
          <Route path="/clientes/config/:tab" element={<MediaHub />} />
          <Route path="/clientes/mediahub/:tab" element={<MediaHub />} />
          <Route
            path="/clientes/mediahub/lojadepublicos"
            element={<AudienceStore />}
          />
          <Route
            path="/clientes/stackdigital/:tab"
            element={<StackDigital />}
          />

          <Route path="*" element={<NotFound img={NotFoundImage} />} />
          <Route path="/pagamento/:id" element={<PaymentOptions />} />
          <Route
            path="/payment/LookingAhead"
            element={<PaymentLookingAhead />}
          />
          <Route path="/plan/:id" element={<PlanOptions />} />
          <Route path="payment/:id/success" element={<PaymentSuccess />} />
          <Route path="/cliente/:id/team" element={<CustomerTeam />} />
          <Route path="/historicos" element={<MessageHistoryList />} />
          <Route
            path="/historico-mensagens/:phone_number"
            element={<MessageHistory />}
          />
          <Route
            path="/carteira/pagamento/:id"
            element={<WalletPaymentOptions />}
          />
          <Route path="/assinatura/:id" element={<SubscriptionOptions />} />
          <Route path="/assinatura/v2/:id" element={<SubscriptionOptions />} />
          <Route
            path="/unit/view/capacidade-operacional/:id"
            element={<OperationalCapacity />}
          />
          <Route
            path="/unit/view/historic/:id"
            element={<CustomerHistoric />}
          />
          <Route
            path="/unit/view/editar-capacidade-operacional/:id"
            element={<EditOperationalCapacity />}
          />
          <Route
            path="/assinatura/:id/sucesso"
            element={<SubscriptionSuccess />}
          />
          <Route
            path="/unit/view/capacidade-operacional/:id"
            element={<OperationalCapacity />}
          />
          <Route
            path="/unit/view/editar-capacidade-operacional/:id"
            element={<EditOperationalCapacity />}
          />

          <Route path="/unit/read/:unitId" element={<UnitVision />} />
          <Route
            path="/unit/project/:id"
            element={<ProjectUserAssignedEdit />}
          />
          <Route path="/unit/sap/invoices/:id" element={<SAPInvoices />} />

          <Route
            path="/newprice/calculadora"
            element={<ProductsCalculator />}
          />

          <Route
            path="/newprice/Oportunities"
            element={<OportunityManager />}
          />
          <Route path="/gateway/headquarter" element={<GatewayHQSeletor />} />
          <Route
            path="/gateway/headquarter/checkout"
            element={<GatewayHQCheckout />}
          />

          {user.unitId === headquarterId && (
            <>
              <Route path="/pipefyRequest" element={<PipefyRequest />} />
              <Route path="/unidades" element={<Units />} />
              <Route path="/unidades/create" element={<CreateUnitPage />} />
              <Route
                path="/unidades/:unitId/register-payment-account"
                element={<RegisterUnitAccount />}
              />
              <Route path="/headquarter" element={<Headquarter />} />
              <Route path="/space/roi-week" element={<RoiWeek />} />
            </>
          )}
          <Route
            path="/clientes/mediahub/"
            element={<ExternalRedirect to={mediaHubUrl || '/'} />}
          />
        </Route>
      </Routes>
    </>
  );
}

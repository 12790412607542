import React, { useEffect, useState, useCallback } from 'react';
import { SingleSelect } from '../../../../../LegacyV4Components';
import { permissionsLead } from '../../../../../../utils/PermissionsOptions/leadPermission';
import { permissionsPaymentGateway } from '../../../../../../utils/PermissionsOptions/paymentGateWay';
import { permissionsCustomer } from '../../../../../../utils/PermissionsOptions/customerPermission';
import { permissionsDash } from '../../../../../../utils/PermissionsOptions/dashPermission';
import { permissionsHead } from '../../../../../../utils/PermissionsOptions/headPermission';
import { useAuth } from '../../../../../../hooks/auth';
import * as S from './styles';
import { BodyPermissions, SetPermissionsFunction } from '../../interfaces';
import { permissionsUser } from '../../../../../../utils/PermissionsOptions/userPermissions';
import { unitPermissionOptions } from '../../../../../../utils/PermissionsOptions/unitPermission';
import { permissionTransition } from '../../../../../../utils/PermissionsOptions/transitionPermission';
import { permissionsBlackBox } from '../../../../../../utils/PermissionsOptions/blackBoxPermission';
import { permissionsDealBroker } from '../../../../../../utils/PermissionsOptions/dealBrokerPermissions';

interface InputItens {
  label: string;
  value: unknown;
  info?: string;
  desc?: string;
}

type SetRolesType =
  | 'paymentGatewayRole'
  | 'dashboardRole'
  | 'unitRole'
  | 'leadbrokerRole'
  | 'blackBoxRole'
  | 'dealBrokerRole'
  | 'headquarterRole'
  | 'userRole'
  | 'transitionRole'
  | 'customerRole';

interface PermissionsProps {
  error: any;
  setPermissions: SetPermissionsFunction;
  userPermissionsUser: BodyPermissions;
  isHeadquarter?: boolean;
  isNowBlocked?: boolean;
}

export const Permissions = ({
  error,
  setPermissions,
  userPermissionsUser,
  isHeadquarter = false,
  isNowBlocked,
}: PermissionsProps) => {
  const [unitRole, setUnitRole] = useState<InputItens[]>([
    unitPermissionOptions[0],
  ]);
  const [headquarterRole, setHeadquarterRole] = useState<InputItens[]>([
    permissionsHead[0],
  ]);

  const [dashboardRole, setDashboardRole] = useState<InputItens[]>([
    permissionsDash[0],
  ]);
  const [customerRole, setCustomerRole] = useState<InputItens[]>([
    permissionsCustomer[0],
  ]);

  const [transitionRole, setTransitionRole] = useState<InputItens[]>([
    permissionTransition[0],
  ]);

  const [paymentGatewayRole, setPaymentGatewayRole] = useState<InputItens[]>([
    permissionsPaymentGateway[0],
  ]);
  const [broker, setBroker] = useState<InputItens[]>([permissionsLead[0]]);
  const [blackBox, setBlackBox] = useState<InputItens[]>([
    permissionsBlackBox[0],
  ]);
  const [dealBroker, setDealBroker] = useState<InputItens[]>([
    permissionsDealBroker[0],
  ]);
  const [userRole, setUserRole] = useState<InputItens[]>([permissionsUser[0]]);

  const { user, permissionsManager } = useAuth();
  const { permissions } = user;

  const handlerSetPermissionsUser = useCallback(() => {
    const setRoleIfValid = <T extends InputItens>(
      permissionList: T[],
      setRoleCallback: React.Dispatch<React.SetStateAction<T[]>>,
      roleName: string | undefined,
      setRole: SetRolesType,
    ): void => {
      const filteredPermissions = permissionList.filter(
        (item) => item.value === roleName,
      );
      if (filteredPermissions.length > 0) {
        setRoleCallback(filteredPermissions);
        setPermissions(setRole, filteredPermissions[0].value as string);
      }
    };

    setRoleIfValid<InputItens>(
      unitPermissionOptions,
      setUnitRole,
      userPermissionsUser?.unit?.roleName,
      'unitRole',
    );

    if (isHeadquarter) {
      setRoleIfValid<InputItens>(
        permissionsHead,
        setHeadquarterRole,
        userPermissionsUser?.headquarter?.roleName,
        'headquarterRole',
      );
    }

    setRoleIfValid<InputItens>(
      permissionsDash,
      setDashboardRole,
      userPermissionsUser?.dashboard?.roleName,
      'dashboardRole',
    );

    setRoleIfValid<InputItens>(
      permissionsCustomer,
      setCustomerRole,
      userPermissionsUser?.customer?.roleName,
      'customerRole',
    );

    setRoleIfValid<InputItens>(
      permissionTransition,
      setTransitionRole,
      userPermissionsUser?.transition?.roleName,
      'transitionRole',
    );

    setRoleIfValid<InputItens>(
      permissionsPaymentGateway,
      setPaymentGatewayRole,
      userPermissionsUser?.paymentGateway?.roleName,
      'paymentGatewayRole',
    );

    setRoleIfValid<InputItens>(
      permissionsLead,
      setBroker,
      userPermissionsUser?.leadbroker?.roleName,
      'leadbrokerRole',
    );

    setRoleIfValid<InputItens>(
      permissionsBlackBox,
      setBlackBox,
      userPermissionsUser?.blackBox?.roleName,
      'blackBoxRole',
    );

    setRoleIfValid<InputItens>(
      permissionsDealBroker,
      setDealBroker,
      userPermissionsUser?.dealBroker?.roleName,
      'dealBrokerRole',
    );

    setRoleIfValid<InputItens>(
      permissionsUser,
      setUserRole,
      userPermissionsUser?.users?.roleName,
      'userRole',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionsUser]);

  const createHandleSetItensSelected = (
    setRole: React.Dispatch<React.SetStateAction<InputItens[]>>,
    permissionKey: SetRolesType,
  ) => {
    return (val: React.SetStateAction<InputItens[]>) => {
      setRole(val);

      if (Array.isArray(val)) {
        setPermissions(
          permissionKey,
          (val[0].value as string) || 'CAN_NOT_SEE',
        );
      }
    };
  };

  useEffect(() => {
    handlerSetPermissionsUser();
  }, [handlerSetPermissionsUser, userPermissionsUser]);

  return (
    <>
      <S.Row style={{ marginBottom: 24, marginTop: 20 }}>
        <h2>Permissões</h2>
      </S.Row>
      {isHeadquarter && (
        <S.ContentRow>
          <SingleSelect
            name="headquarterRole"
            label="Headquarter"
            itensSelected={headquarterRole}
            placeholder="Escolha um item"
            options={permissionsHead}
            setItensSelected={createHandleSetItensSelected(
              setHeadquarterRole,
              'headquarterRole',
            )}
            disabled={!isHeadquarter}
            error={error.headquarterRole?.message}
          />
          <S.Info>
            {permissions?.unit?.admin ? (
              <span>{headquarterRole[0]?.desc}</span>
            ) : (
              <span className="disabled">
                Você deve ser admin para alterar essa permissão
              </span>
            )}
            {headquarterRole.length === 0 && (
              <S.Error
                style={{ color: 'red' }}
                className={!permissions?.unit?.admin ? 'disabled' : ''}
              >
                Selecione uma das opções
              </S.Error>
            )}
          </S.Info>
        </S.ContentRow>
      )}
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="unitRole"
          label="Unidade"
          itensSelected={unitRole}
          placeholder="Escolha um item"
          options={unitPermissionOptions}
          setItensSelected={createHandleSetItensSelected(
            setUnitRole,
            'unitRole',
          )}
          disabled={!permissions?.unit?.admin}
          error={error.unitRole?.message}
        />
        <S.Info>
          {permissions?.unit?.admin ? (
            <span>{unitRole[0]?.desc}</span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {unitRole.length === 0 && (
            <span
              style={{ color: 'red' }}
              className={!permissions?.unit?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="dashboardRole"
          label="Central de Dashboards"
          itensSelected={dashboardRole}
          placeholder="Selecione uma permissão"
          options={permissionsDash}
          setItensSelected={createHandleSetItensSelected(
            setDashboardRole,
            'dashboardRole',
          )}
          disabled={!permissions?.dashboard?.admin}
          error={error.dashboardRole?.message}
        />
        <S.Info>
          {permissions?.dashboard?.admin ? (
            <span className={!permissions.dashboard?.admin ? 'disabled' : ''}>
              {dashboardRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {dashboardRole.length === 0 && (
            <span style={{ color: 'red' }}>Selecione uma das opções</span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          name="userRole"
          label="Usuários"
          itensSelected={userRole}
          placeholder="Selecione uma permissão"
          options={permissionsUser}
          setItensSelected={createHandleSetItensSelected(
            setUserRole,
            'userRole',
          )}
          disabled={!permissions?.users?.admin}
          error={error.userRole?.message}
        />
        <S.Info>
          {permissions?.users?.admin ? (
            <span className={!permissions?.users?.admin ? 'disabled' : ''}>
              {userRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {userRole.length === 0 && (
            <span style={{ color: 'red' }}>Selecione uma das opções</span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          itensSelected={customerRole}
          setItensSelected={createHandleSetItensSelected(
            setCustomerRole,
            'customerRole',
          )}
          placeholder="Selecione uma permissão"
          label={isHeadquarter ? 'Clientes' : 'Meus Clientes'}
          options={permissionsCustomer}
          disabled={!permissions?.customer?.admin}
          error={error.customerRole?.message}
        />
        <S.Info>
          {permissions?.customer?.admin ? (
            <span className={!permissions.customer?.admin ? 'disabled' : ''}>
              {customerRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          itensSelected={transitionRole}
          setItensSelected={createHandleSetItensSelected(
            setTransitionRole,
            'transitionRole',
          )}
          placeholder="Selecione uma permissão"
          label={isHeadquarter ? 'Transionar Clientes' : 'Meus Clientes'}
          options={permissionTransition}
          disabled={!permissions?.transition?.admin}
          error={error.transitionRole?.message}
        />
        <S.Info>
          {permissions?.transition?.admin ? (
            <span className={!permissions?.transition?.admin ? 'disabled' : ''}>
              {transitionRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          itensSelected={paymentGatewayRole}
          setItensSelected={createHandleSetItensSelected(
            setPaymentGatewayRole,
            'paymentGatewayRole',
          )}
          placeholder="Selecione uma permissão"
          label="Gateway de pagamento"
          options={permissionsPaymentGateway}
          disabled={!permissions?.paymentGateway?.admin}
          error={error.paymentGatewayRole?.message}
        />
        <S.Info>
          {permissions?.paymentGateway?.admin ? (
            <span
              className={!permissions.paymentGateway?.admin ? 'disabled' : ''}
            >
              {paymentGatewayRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          itensSelected={broker}
          setItensSelected={createHandleSetItensSelected(
            setBroker,
            'leadbrokerRole',
          )}
          placeholder="Selecione uma permissão"
          label="Lead Broker"
          info={isNowBlocked ? 'Esta unidade está bloqueada no Leadbroker' : ''}
          options={permissionsLead}
          disabled={!permissions?.leadbroker?.admin || isNowBlocked}
          error={error.leadbrokerRole?.message}
        />
        <S.Info>
          {permissions?.leadbroker?.admin ? (
            <span
              className={
                !permissions.leadbroker?.admin || isNowBlocked ? 'disabled' : ''
              }
            >
              {broker[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {broker.length === 0 && (
            <span
              style={{ color: 'red' }}
              className={!permissions?.leadbroker?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          itensSelected={blackBox}
          setItensSelected={createHandleSetItensSelected(
            setBlackBox,
            'blackBoxRole',
          )}
          placeholder="Selecione uma permissão"
          label="Black Box"
          info={isNowBlocked ? 'Esta unidade está bloqueada na Black Box' : ''}
          options={permissionsBlackBox}
          disabled={
            !permissionsManager?.blackBoxPermission?.enableInvestorsHQAccess ||
            !permissions?.blackBox?.admin ||
            isNowBlocked
          }
          error={error.blackBoxRole?.message}
        />
        <S.Info>
          {permissionsManager?.blackBoxPermission?.enableInvestorsHQAccess ||
          permissions?.blackBox?.admin ? (
            <span
              className={
                !permissions?.blackBox?.admin || isNowBlocked ? 'disabled' : ''
              }
            >
              {broker[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {broker.length === 0 && (
            <span
              style={{ color: 'red' }}
              className={!permissions?.blackBox?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          itensSelected={dealBroker}
          setItensSelected={createHandleSetItensSelected(
            setDealBroker,
            'dealBrokerRole',
          )}
          placeholder="Selecione uma permissão"
          label="Deal Broker"
          info={
            isNowBlocked ? 'Esta unidade está bloqueada no Deal Broker' : ''
          }
          options={permissionsDealBroker}
          disabled={!permissions?.dealBroker?.admin || isNowBlocked}
          error={error.dealBrokerRole?.message}
        />
        <S.Info>
          {permissions?.dealBroker?.admin ? (
            <span
              className={
                !permissions.dealBroker?.admin || isNowBlocked ? 'disabled' : ''
              }
            >
              {broker[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {broker.length === 0 && (
            <span
              style={{ color: 'red' }}
              className={!permissions?.dealBroker?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
    </>
  );
};
